html {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(84, 73, 75); */
  /* background-color: #FFFCF9; */
  /* background-color: #B1EDE8; */
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background: linear-gradient(180deg, rgba(177,237,232,1) 0%, rgba(177,237,232,1) 75%, rgba(255,255,255,1) 100%); */
  background: rgb(245, 255, 254);
/* background: linear-gradient(135deg, rgb(239, 253, 252) 0%, rgba(224,251,249,1) 38%, rgba(255,255,255,1) 100%); */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
