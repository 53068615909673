.skeletonTodo {
    position: relative;
    border-radius: 0.5rem;
    padding: 0.5rem;
    height: 72px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 12fr 1fr;
    margin-bottom: 16px;
    align-items: center;
}

.checkbox-container {
    min-width: 56px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;
}

.skeleton {
    background: #ddd;
    border-radius: 4px;
}

.skeleton.text {
    width: 90%;
    height: 14px;
}

.skeleton.title {
    width: 40%;
    height: 14px;
    margin-bottom: 8px;
}

.skeleton.checkbox {
    height: 20px;
    width: 20px;
}

.skeletonTodo.dark {
    background: #444;
}

.skeletonTodo.dark .skeleton {
    background: #303030;
}

/* Shimmer animation */
.shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
}

.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.2);
}

.dark .shimmer {
    background: rgba(255, 255, 255, 0.05)
}

@keyframes loading {
    0% { transform: translateX(-100%) }
    50% { transform: translateX(-60%) }
    100% { transform: translateX(150%) }
}